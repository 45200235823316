'use client'

import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Leaf, Sun, Droplets, Award, Heart, Coffee } from 'lucide-react'

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [selectedImage, setSelectedImage] = useState(null)

  const handleDoubleClick = (src) => {
    setSelectedImage(src)
  }

  const closeModal = () => {
    setSelectedImage(null)
  }

  const products = [
    {
      name: "Haldi Powder",
      description:
        "Made from premium turmeric roots, our Haldi Powder is rich in color, aroma, and health benefits, perfect for daily cooking and medicinal use.",
      src: require("../image/haldi_powder.jpg"),
      icon: <Leaf className="w-6 h-6 text-yellow-600" />,
      benefits: [
        "Rich in antioxidants and curcumin",
        "Boosts immunity and digestion",
        "Adds color and flavor to dishes",
      ],
    },
    {
      name: "Malvani Masala",
      description:
        "A rich and aromatic spice blend, our Malvani Masala brings the bold and authentic flavors of coastal Maharashtra to your seafood and meat dishes.",
      src: require("../image/malvani_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-red-700" />,
      benefits: [
        "Authentic coastal Maharashtrian flavor",
        "Perfect for seafood, chicken, and mutton dishes",
        "Blend of bold and aromatic spices",
      ],
    },
    {
      name: "Mutton Masala",
      description:
        "A robust and aromatic blend of spices, our Mutton Masala enhances the rich and deep flavors of mutton curries, gravies, and kebabs.",
      src: require("../image/mutton_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-red-800" />,
      benefits: [
        "Enhances flavor and aroma",
        "Perfect for mutton curries and grills",
        "Blend of traditional and aromatic spices",
      ],
    },
    {
      name: "Misal Masala",
      description:
        "A spicy and flavorful blend, our Misal Masala adds the perfect heat and aroma to Maharashtra’s favorite spicy Misal Pav dish.",
      src: require("../image/misal_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-orange-700" />,
      benefits: [
        "Authentic Maharashtrian taste",
        "Perfect for spicy Misal Pav",
        "Blend of aromatic and bold spices",
      ],
    },
    {
      name: "Mirchi Powder",
      description:
        "Fiery and flavorful, our Mirchi Powder is made from the finest sun-dried red chilies, adding rich color and heat to your dishes.",
      src: require("../image/mirchi_powder.jpg"),
      icon: <Leaf className="w-6 h-6 text-red-700" />,
      benefits: [
        "Enhances spice and color",
        "Made from premium red chilies",
        "Perfect for curries, marinades, and chutneys",
      ],
    },
    {
      name: "Dhana Powder",
      description:
        "Made from premium coriander seeds, our Dhana Powder adds a fresh, citrusy, and earthy flavor to a variety of Indian dishes.",
      src: require("../image/dhana_powder.jpg"),
      icon: <Leaf className="w-6 h-6 text-green-600" />,
      benefits: [
        "Enhances flavor and aroma",
        "Rich in antioxidants",
        "Perfect for curries, dals, and spice blends",
      ],
    },
    {
      name: "Chicken Masala",
      description:
        "A bold and aromatic blend of spices, our Chicken Masala enhances the rich flavors of chicken curries, tandoori dishes, and marinades.",
      src: require("../image/chicken_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-orange-700" />,
      benefits: [
        "Enhances flavor and aroma",
        "Perfect for curries and grills",
        "Made with a blend of traditional spices",
      ],
    },
    {
      name: "Chole Masala",
      description:
        "A flavorful blend of spices, our Chole Masala is specially crafted to enhance the rich, tangy, and spicy taste of traditional chole dishes.",
      src: require("../image/chole_masale.jpg"),
      icon: <Leaf className="w-6 h-6 text-brown-700" />,
      benefits: [
        "Authentic North Indian flavor",
        "Perfect for chole, chana, and curries",
        "Rich blend of aromatic spices",
      ],
    },
    {
      name: "Kala Masala",
      description:
        "A traditional Maharashtrian spice blend, Kala Masala is a rich mix of roasted spices that adds deep flavor and aroma to curries, vegetables, and dals.",
      src: require("../image/kala_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-brown-700" />,
      benefits: [
        "Authentic Maharashtrian flavor",
        "Deep, smoky aroma",
        "Perfect for curries, dals, and vegetables",
      ],
    },
    {
      name: "Kanda Lasun Masala",
      description:
        "Aromatic and flavorful, our Kanda Lasun Masala is a blend of onion, garlic, and rich spices, perfect for enhancing traditional Indian dishes.",
      src: require("../image/kandalasun2_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-red-600" />,
      benefits: [
        "Enhances taste and aroma",
        "Rich in traditional spices",
        "Perfect for curries and gravies",
      ],
    },
    {
      name: "Frozen Peas",
      description:
        "Supporting farmers, delivering flavor. Our peas are flash-frozen at peak ripeness to lock in nutrients and taste.",
      src: require("../image/peas.jpg"),
      icon: <Leaf className="w-6 h-6 text-green-600" />,
      benefits: [
        "Rich in vitamins A and C",
        "High in fiber",
        "Versatile ingredient",
      ],
    },
    {
      name: "Frozen Sweet Corn",
      description:
        "Premium-grade, hand-selected kernels. Enjoy the taste of summer all year round with our sweet and crunchy corn.",
      src: require("../image/corn.jpg"),
      icon: <Sun className="w-6 h-6 text-yellow-600" />,
      benefits: [
        "Natural sweetness",
        "Source of antioxidants",
        "Great for grilling",
      ],
    },
    {
      name: "Pav Bhaji Masala",
      description:
        "Our Pav Bhaji Masala is a blend of aromatic spices specially crafted to enhance the vibrant flavors of pav bhaji, delivering a perfect balance of heat and zest.",
      src: require("../image/pavbhaji_masala.jpg"),
      icon: <Sun className="w-6 h-6 text-orange-600" />,
      benefits: [
        "Enhances the iconic pav bhaji taste",
        "Perfect blend of heat and zest",
        "Versatile for street food and home recipes",
      ],
    },
    {
      name: "Sambar Masala",
      description:
        "Our Sambar Masala is a carefully curated blend of spices that brings out the rich, tangy flavors of traditional South Indian sambar.",
      src: require("../image/sambhar_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-red-600" />,
      benefits: [
        "Enhances tangy sambar flavor",
        "Rich, aromatic blend",
        "Authentic South Indian taste",
      ],
    },
    {
      name: "Garam Masala",
      description:
        "Our Garam Masala is a classic spice blend that adds warmth and depth to a wide range of dishes, from curries to stews.",
      src: require("../image/garam_masala.jpg"),
      icon: <Award className="w-6 h-6 text-red-600" />,
      benefits: [
        "Adds warmth and depth",
        "Enhances flavor profiles",
        "Versatile for various cuisines",
      ],
    },
    {
      name: "Biryani Masala",
      description:
        "Our Biryani Masala is a fragrant blend of spices designed to elevate your biryani with a perfect balance of aroma, color, and flavor.",
      src: require("../image/hyderabadi_biryani_masala.jpg"),
      icon: <Sun className="w-6 h-6 text-orange-600" />,
      benefits: [
        "Elevates biryani dishes",
        "Rich, aromatic blend",
        "Perfect balance of spices",
      ],
    },
    {
      name: "Fish Masala",
      description:
        "Our Fish Masala is a zesty and robust blend of spices that enhances the delicate flavor of fish, perfect for curries and grills.",
      src: require("../image/fish_masala.jpg"),
      icon: <Droplets className="w-6 h-6 text-blue-600" />,
      benefits: [
        "Enhances fish flavor",
        "Ideal for seafood curries",
        "Robust and zesty blend",
      ],
    },
    {
      name: "Goda Masala",
      description:
        "Our Goda Masala is a unique Maharashtrian spice blend with a mildly sweet and aromatic profile, perfect for enhancing dals, vegetables, and traditional dishes.",
      src: require("../image/goda_masala.jpg"),
      icon: <Leaf className="w-6 h-6 text-brown-700" />,
      benefits: [
        "Mildly sweet and aromatic",
        "Enhances traditional flavors",
        "Authentic Maharashtrian spice blend",
      ],
    },
    {
      name: "Dehydrated Onions",
      description:
        "A burst of natural flavor, dehydrated for your delight. Convenient and long-lasting, perfect for various cuisines.",
      src: require("../image/onion.jpg"),
      icon: <Droplets className="w-6 h-6 text-purple-600" />,
      benefits: [
        "Extended shelf life",
        "Intense flavor",
        "Easy to store",
      ],
    },
    {
      name: "Organic Spices",
      description:
        "A carefully curated selection of organic spices to elevate your culinary creations. Sourced from the finest farms in India.",
      src: require("../image/organic_masale.jpg"),
      icon: <Coffee className="w-6 h-6 text-red-600" />,
      benefits: [
        "100% organic",
        "Rich in antioxidants",
        "Authentic flavors",
      ],
    },
    {
      name: "Fresh Vegetables",
      description:
        "Farm-fresh vegetables delivered straight to your doorstep. Experience the crisp taste of locally sourced produce.",
      src: require("../image/vegetables.jpg"),
      icon: <Heart className="w-6 h-6 text-green-600" />,
      benefits: [
        "Locally sourced",
        "Pesticide-free",
        "Maximum freshness",
      ],
    },
    {
      name: "Pulses and Lentils",
      description:
        "High-quality pulses and lentils, rich in protein and fiber. A staple for healthy and hearty meals.",
      src: require("../image/lentils.jpg"),
      icon: <Award className="w-6 h-6 text-yellow-600" />,
      benefits: [
        "High in protein",
        "Rich in fiber",
        "Versatile cooking options",
      ],
    },
  ]

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-b from-green-50 to-white">
      <motion.h1
        className="text-5xl font-bold mb-8 text-center"
        style={{ color: "rgb(60, 61, 55)" }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Our Products
      </motion.h1>
      <motion.p
        className="text-xl text-center mb-12 text-gray-600"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Discover our range of high-quality, sustainably sourced products
      </motion.p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product, index) => (
          <motion.div
            key={index}
            className="flex flex-col border rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl bg-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.03 }}
          >
            <div className="mb-4 relative overflow-hidden rounded-lg">
              <img
                src={product.src}
                alt={product.name}
                className="w-full h-48 object-cover transition-transform duration-300 hover:scale-110 cursor-pointer"
                onDoubleClick={() => handleDoubleClick(product.src)}
              />
              <div className="absolute top-2 right-2 bg-white rounded-full p-2">
                {product.icon}
              </div>
            </div>
            <h2
              className="text-2xl font-semibold mb-2"
              style={{ color: "rgb(60, 61, 55)" }}
            >
              {product.name}
            </h2>
            <p className="text-gray-600 mb-4 flex-grow">
              {product.description}
            </p>
            <div className="mt-4">
              <h3 className="font-semibold mb-2 text-green-700">Benefits:</h3>
              <ul className="list-disc list-inside text-gray-600">
                {product.benefits.map((benefit, i) => (
                  <li key={i}>{benefit}</li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
      <motion.div
        className="mt-16 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <h2
          className="text-3xl font-bold mb-4"
          style={{ color: "rgb(60, 61, 55)" }}
        >
          Why Choose Our Products?
        </h2>
        <p className="text-xl text-gray-600 mb-8">
          At Apex International, we're committed to quality, sustainability,
          and supporting local farmers.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              title: "Sustainably Sourced",
              icon: <Leaf className="w-8 h-8 text-green-600" />,
              description: "We prioritize eco-friendly farming practices",
            },
            {
              title: "Premium Quality",
              icon: <Award className="w-8 h-8 text-yellow-600" />,
              description: "Rigorous quality control for the best products",
            },
            {
              title: "Supporting Communities",
              icon: <Heart className="w-8 h-8 text-red-600" />,
              description: "Fair trade practices to empower local farmers",
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.7 + index * 0.1 }}
            >
              <div className="flex justify-center mb-4">{item.icon}</div>
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: "rgb(60, 61, 55)" }}
              >
                {item.title}
              </h3>
              <p className="text-gray-600">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Modal for full view image */}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={closeModal}
        >
          <motion.img
            src={selectedImage}
            alt="Full view"
            className="max-w-full max-h-full rounded"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          />
        </div>
      )}
    </div>
  )
}

export default Products
